<template>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="
        !Guid.IsNullOrEmpty(menuStore.CurrentSubgroupId) &&
        menuStore.CurrentSubgroups &&
        menuStore.CurrentSubgroups.length > 1
      "
      class="v-subgroups__dol-guldur v-py-xs"
      data-test-id="menu-subgroups-links-container"
    >
      <arora-chips
        with-links
        :chips="menuStore.CurrentSubgroups"
        data-test-id="menu-subgroups-links"
        theme="secondary"
        v-model:selected="selectedSubgroup"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { Guid } from '~api/consts'

import type { GUID } from '@arora/common'

const menuStore = useMenuStore()

const selectedSubgroup = computed<GUID>({
  get: () => menuStore.CurrentSubgroupId ?? menuStore.CurrentGroupId ?? Guid.Empty,
  set: (value) => {
    const foundGroup = menuStore.CurrentSubgroups!.find((g) => g.ID === value)

    if (foundGroup) navigateTo(foundGroup.Link)
  }
})

onMounted(() => {
  if (
    !menuStore.CurrentSubgroupId &&
    menuStore.CurrentSubgroups &&
    menuStore.CurrentSubgroups.length > 0
  ) {
    selectedSubgroup.value = menuStore.CurrentSubgroups[0].ID
  }
})
</script>

<style lang="scss">
@use '~/assets/mixins';

.v-subgroups__dol-guldur {
  @include mixins.sm {
    margin-top: 1rem;
  }
}
</style>
