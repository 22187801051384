export const appCdnURL = "https://cdn.mnogo.menu/w2/upload/generated/64d836cd-bd93-4a0a-bf1f-f651d3c302b2/2d48bea8-ee1e-4633-9b2e-a5f40049fe89/dist"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appHead = {"htmlAttrs":{"lang":"ru"},"meta":[{"name":"charset","content":"utf-8"},{"name":"website-version","content":"T903.553"},{"name":"cache-control","content":"no-cache"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"},{"name":"theme-color","content":"#EE312D"},{"property":"og:type","content":"food"},{"property":"og:image","content":"https://milano-buzuluk.mnogo.menu/upload/64d836cd-bd93-4a0a-bf1f-f651d3c302b2/file_manager/milano-buzuluk.mnogo.menu/theme/popup-mob.jpg"},{"property":"og:site_name","content":"https://milano-buzuluk.mnogo.menu"}],"link":[{"rel":"icon","type":"image/png","href":"https://milano-buzuluk.mnogo.menu/upload/64d836cd-bd93-4a0a-bf1f-f651d3c302b2/file_manager/milano-buzuluk.mnogo.menu/theme/favicon.png"}]}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true