import type { IMetric, RestaurantSettings } from '~types/restaurantStore'

import { type GUID } from '@arora/common'

const mindboxScriptKey = 'mindbox-script-id'

export default class MindboxMetric implements IMetric {
  mindbox: any

  constructor(data: RestaurantSettings) {
    //add script to page
    const scriptYandexMap = document.createElement('script')

    scriptYandexMap.setAttribute('id', mindboxScriptKey)
    scriptYandexMap.setAttribute('src', `https://${data.MindboxDomainApi}/scripts/v1/tracker.js`)

    document.head.appendChild(scriptYandexMap)

    scriptYandexMap.addEventListener('load', () => {
      this.mindbox = window.mindbox || {}

      this.mindbox.queue = this.mindbox.queue || []
      this.mindbox('create', {
        endpointId: data.MindboxSiteEndpoint
      })
    })
  }

  async sendMetricAddToCart(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _priceModified: number,
    _count: number,
    _optionID: GUID
  ): Promise<void> {
    return Promise.resolve(undefined)
  }

  async sendMetricAfterOrder(_orderId: GUID, _totalAmountToPay: number): Promise<void> {
    return Promise.resolve(undefined)
  }

  sendMetricBeforeOrder(): Promise<void> {
    return Promise.resolve(undefined)
  }

  sendMetricCartView(): Promise<void> {
    return Promise.resolve(undefined)
  }

  async sendMetricRemoveFromCart(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _count: number
  ): Promise<void> {}

  async SendMetricsProductView(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _priceModified: number,
    _count: number,
    _optionID: GUID | undefined
  ): Promise<void> {
    return Promise.resolve(undefined)
  }
}
