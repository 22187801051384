import MakeRequest from '~api/request'

import type {
  ActivityMap,
  DiscountInfo,
  GiftsMultiple,
  GiftsRanged,
  MenuRelated,
  ProductInList,
  ProductSearchResult
} from '~types/menuStore'

import { type GUID, RequestMethod } from '@arora/common'

export default {
  async getDiscountTimers(groupId: GUID | null = null): Promise<DiscountInfo> {
    const getParameters: Map<string, string> = new Map()

    if (groupId) {
      getParameters.set('forGroup', groupId)
    }

    return await MakeRequest<DiscountInfo>('/ajax/discounttimeinfov2', getParameters)
  },
  async getGiftsMultiple(): Promise<GiftsMultiple> {
    return await MakeRequest<GiftsMultiple>('/api/json/gifts-multiple')
  },
  async getGiftsRanged(): Promise<GiftsRanged> {
    return await MakeRequest<GiftsRanged>('/api/json/gifts-ranged')
  },
  async getActivityMap(): Promise<ActivityMap> {
    return await MakeRequest<ActivityMap>(
      '/api/json/menu/activityMap',
      null,
      RequestMethod.GET,
      null,
      'high'
    )
  },
  async loadRelated(productId: string | undefined): Promise<MenuRelated> {
    return await MakeRequest<MenuRelated>(
      '/api/json/menu/related',
      productId ? new Map([['productId', productId]]) : null
    )
  },
  async loadRecommended(groupId: string | undefined = undefined): Promise<ProductInList[]> {
    return await MakeRequest<ProductInList[]>(
      '/api/json/menu/recommended',
      groupId ? new Map([['groupId', groupId]]) : null
    )
  },
  async loadProductsContainingById(productId: string): Promise<ProductInList[]> {
    return await MakeRequest<ProductInList[]>(
      '/api/json/menu/productsContainingProduct',
      new Map([['productId', productId]])
    )
  },
  async loadAccountPointsMenu(): Promise<ProductInList[]> {
    return await MakeRequest<ProductInList[]>('/api/json/menu/accountPointsMenu')
  },
  async loadAccountFavoritesMenu(): Promise<ProductInList[]> {
    return await MakeRequest<ProductInList[]>('/api/json/menu/accountFavoritesMenu')
  },
  async loadProductsBySearchString(
    substring: string,
    substringAlt: string
  ): Promise<ProductSearchResult[]> {
    return await MakeRequest<ProductSearchResult[]>(
      '/api/json/menu/find',
      new Map([
        ['text', substring],
        ['textAlt', substringAlt]
      ])
    )
  }
}
