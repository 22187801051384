<template>
  <div class="v-container v-p-0">
    <div class="v-row v-mb-md">
      <div
        class="v-col-12 v-mb-sm v-account-management--title v-title"
        v-html="translate(onlyBySms ? 'accountManagement.titleLogin' : 'accountManagement.register')"
      />
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <common-phone-input
          focus-on-mount
          :label="translate('accountManagement.enterPhoneNumber')"
          :validator-form="validatorForm"
          v-model:phone="inputPhone"
        />
      </div>
    </div>
    <div class="v-d-flex v-flex-row v-row-auth-variants v-mb-md">
      <div
        v-if="
          authVariant === AuthVariant.Telegram ||
          authVariant === AuthVariant.Flashcall ||
          authVariant === AuthVariant.Sms
        "
        class="v-w-100 v-get-the-code"
        v-html="translate('accountManagement.getTheCode')"
      />
      <div
        v-for="variant in appConfig.RestaurantSettingsPreRun.AuthVariants"
        :key="`authVariant-${variant}`"
        :class="`v-col-auth-variant v-text-center v-auth-variants-${appConfig.RestaurantSettingsPreRun.AuthVariants.length}`"
      >
        <input
          :id="`auth-variant-${variant}`"
          v-form-validator="{
            Form: validatorForm,
            Value: authVariant,
            Required: true,
            Validations: []
          }"
          :value="variant"
          type="radio"
          v-model="authVariantModel"
        />
        <label
          class="v-btn v-btn-auth-variant v-align-items-center"
          :for="`auth-variant-${variant}`"
        >
          <span class="v-icon">
            <icon-social-telegram v-if="variant === AuthVariant.Telegram" />
            <icon-general-phone v-else-if="variant === AuthVariant.Flashcall" />
            <icon-general-message v-else-if="variant === AuthVariant.Sms" />
          </span>
          <span
            class="v-name"
            v-html="authVariantText(variant)"
          />
        </label>
      </div>
      <div class="v-auth-type--help__first-stage v-col-12">
        <div
          v-if="authVariant === AuthVariant.Telegram"
          v-html="translate('accountManagement.telegramHelp')"
        />
        <div
          v-else-if="authVariant === AuthVariant.Flashcall"
          v-html="translate('accountManagement.flashcallHelp')"
        />
        <div
          v-else-if="authVariant === AuthVariant.Sms"
          v-html="translate('accountManagement.smsHelp')"
        />
      </div>
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <lazy-common-captcha />
      </div>
    </div>
    <div class="v-row v-mb-md v-personal-data">
      <div
        v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
        class="v-col-12"
      >
        <span
          class="v-mr-xxs"
          v-html="translate('accountManagement.personalDataMessage')"
        />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
          :label="translate('accountManagement.personalDataMessageLink')"
        />
      </div>
      <div
        v-else
        class="v-col-12"
      >
        <span v-html="translate('accountManagement.agreementText')" />
        <arora-nuxt-link
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.AgreementLink"
          :label="translate('accountManagement.agreementLink')"
        />
      </div>
    </div>

    <div class="v-row v-mb-md">
      <div
        v-if="!onlyBySms"
        class="v-col-6"
      >
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link"
          ignore-settings
          :label="translate('accountManagement.alreadyRegistered')"
          @click="setLKModeLogin"
        />
      </div>
      <div :class="[onlyBySms ? 'v-col-12 v-text-right' : 'v-col-6']">
        <arora-button
          class-name="v-btn-verify-main-contact"
          :disabled="
            verifyDisabled ||
            clicked ||
            !!timerDiff ||
            (appConfig.RestaurantSettingsPreRun.AuthVariants.length > 1 && !authVariant)
          "
          :label="
            !!timerDiff
              ? translate('accountManagement.verifyMainContactLeft', {
                  timer: timerDiff?.toFormat('mm:ss')
                })
              : translate('accountManagement.verifyMainContact')
          "
          @click="async () => await verifyMainContact()"
        />
      </div>

      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="loginError"
          class="v-col-12 v-text-center v-error-color"
          v-html="loginError"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AuthVariant, LKMode } from '~api/consts'

import type { RegisterPayload } from '~types/accountStore'
import type { AccountManagementCommon } from '~types/props'

import { type ServerError, useValidationStore, vFormValidator } from '@arora/common'
import { DateTime, type Duration } from 'luxon'
import { getActivePinia } from 'pinia'

const { fields, phone, authVariant, onlyBySms } = defineProps<
  AccountManagementCommon & {
    fields: RegisterPayload
  }
>()

const emit = defineEmits(['update:mode', 'update:phone', 'update:fields', 'update:authVariant'])

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { handleAuthError } = useAccount()
const { phoneLength } = usePhoneNumber()
const { translate, clean } = useI18nSanitized()
const { eventEmit } = useEmitter()

const loginError = ref<string | null>(null)
const timerDiff = ref<Duration | null>(null)

async function handleErrorMessage(error: ServerError): Promise<void> {
  loginError.value = await handleAuthError(error)
}

const inputPhone = computed<string>({
  get() {
    return phone
  },
  set(value: string) {
    emit('update:phone', clean(value ?? ''))
  }
})

const authVariantModel = computed<AuthVariant | null | undefined>({
  get() {
    return authVariant
  },
  set(value: AuthVariant | null | undefined) {
    emit('update:authVariant', value)
  }
})

const registerFields = computed<RegisterPayload>({
  get() {
    return fields
  },
  set(value: RegisterPayload) {
    emit('update:fields', value)
  }
})

const verifyDisabled = computed<boolean>(() => {
  return inputPhone.value?.length !== phoneLength.value
})
const clicked = ref<boolean>(false)

function setLKModeLogin(): void {
  emit('update:mode', LKMode.Login)
}

let timer: ReturnType<typeof setTimeout>
let timerEnd: DateTime

function timerTick(): void {
  timerDiff.value = timerEnd.diffNow(['minutes', 'seconds'])

  if (timerDiff.value.minutes <= 0 && timerDiff.value.seconds <= 0) {
    clearTimeout(timer)
    clicked.value = false
    timerDiff.value = null
  }
}

const validatorForm = 'register1'

async function verifyMainContact(): Promise<void> {
  clicked.value = true

  if (inputPhone.value?.length !== phoneLength.value) return //for enter keypress

  registerFields.value = await restaurantStore.validateCaptcha(registerFields.value)

  registerFields.value.country = appConfig.VueSettingsPreRun.SelectedLanguage
  registerFields.value.phone = inputPhone.value ?? null

  if (!validationStore.formPassedCheck(validatorForm)) {
    clicked.value = false

    return
  }

  const error = await accountStore.verifyMainContact(
    registerFields.value,
    appConfig.RestaurantSettingsPreRun.AuthVariants.length === 0 ? AuthVariant.Default : authVariant!
  )
  await handleErrorMessage(error)

  if (error.code === 0) {
    emit('update:mode', onlyBySms ? LKMode.LoginBySmsSecondStage : LKMode.RegisterSecondStage)

    return
  }

  eventEmit('v-reset-captcha')

  clicked.value = false
  timerEnd = DateTime.now().plus({ seconds: 45 })
  timer = setInterval(timerTick, 1000)
}
</script>
