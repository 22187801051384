import cartAPI from '~api/cart'
import clientStateAPI from '~api/clientState'
import { Guid, OrderStatus, OrderType } from '~api/consts'

import type { Terminal } from '~types/addressStore'
import type {
  addressChangeObject,
  AddToCartPayload,
  CartAnswer,
  CartItem,
  ClientState,
  ClientStoreStore,
  DeliveryTime,
  OrderInfo,
  OrderInfoStatus,
  OrderProcessing,
  PriceChange,
  PromoInfo,
  SelectedDeliveryAddress,
  StateChangeAnswer,
  StateOrderData,
  StateOrderDataPayload,
  TimeRestrictions,
  UpdateCartItemPayload
} from '~types/clientStore'
import type { StoreState } from '~types/common'
import type { BreadcrumbsLinks } from '~types/props'

import { computed, ref } from 'vue'

import { type GUID, useCommon } from '@arora/common'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'

export const useClientStore = defineStore('clientStore', (): ClientStoreStore => {
  const ClientState = ref<StoreState<ClientState>>({
    state: null,
    data: null,
    error: null
  })
  const OrdersInfo = ref<Record<GUID, StoreState<OrderInfo>>>({})
  const OrdersForCancel = ref<StoreState<OrderInfo[]>>({
    state: null,
    data: null,
    error: null
  })
  const TimeRestrictions = ref<TimeRestrictions>({
    error: null,
    state: null,
    data: null,
    wasUpdatedAt: null,
    minUpdatePeriod: 5
  })
  const DeliveryTime = ref<DeliveryTime>({
    ASAP: true,
    timestamp: 0,
    minutesStep: 5
  })
  const Breadcrumbs = ref<BreadcrumbsLinks[]>([])
  const BreadcrumbBackLink = ref<string | null>(null)
  const BreadcrumbBackText = ref<string>('')
  const productsWithPriceChanges = ref<PriceChange[]>([])

  const averageTimeWithDelayMs = computed<number>(
    () =>
      ((ClientState.value.data?.AverageTimeSec ?? 0) + (ClientState.value.data?.DelayTimeSec ?? 0)) *
      1000
  )
  const haveDelayInAverageTime = computed<boolean>(() => (ClientState.value.data?.DelayTimeSec ?? 0) > 0)

  const orderTypeNotSelected = computed<boolean>(
    () => (ClientState.value.data?.OrderType ?? OrderType.Default) === OrderType.Default
  )
  const selfService = computed<boolean>(() => ClientState.value.data?.OrderType === OrderType.NoShipment)
  const courierDelivery = computed<boolean>(
    () => ClientState.value.data?.OrderType === OrderType.CourierDelivery
  )
  const inHall = computed<boolean>(() => ClientState.value.data?.OrderType === OrderType.InHall)

  const orderTypeText = computed<string>(() => {
    if (courierDelivery.value) return 'orderType.delivery'
    if (selfService.value) return 'orderType.selfService'
    if (inHall.value) return 'orderType.inHall'

    return ''
  })

  const selectedTerminalId = computed<GUID>(() => {
    switch (ClientState.value.data?.OrderType) {
      case OrderType.CourierDelivery: {
        return ClientState.value.data?.SelectedDeliveryAddress?.TerminalID ?? Guid.Empty
      }
      case OrderType.NoShipment: {
        return ClientState.value.data?.SelectedSelfserviceTerminalID ?? Guid.Empty
      }
      case OrderType.InHall: {
        return ClientState.value.data?.SelectedInHall?.TerminalID ?? Guid.Empty
      }
      default: {
        return Guid.Empty
      }
    }
  })

  async function onChangeSelfServiceTerminal(terminal: Terminal): Promise<void> {
    if (!terminal.Active) {
      return
    }
    const addressStore = useAddressStore()
    const popupStore = usePopupStore()

    const terminalCheck = await addressStore.checkTerminal(terminal.ID)

    if (!terminalCheck.enableTerminal) {
      await popupStore.showError(terminalCheck.unavailableMessage, 'unavailable-terminal')

      return
    }

    await updateSelfServiceTerminal({
      terminalId: terminal.ID
    })
  }

  async function initClientState(): Promise<void> {
    if (!import.meta.client) return

    if (ClientState.value.state !== 'success' && ClientState.value.state !== 'loading') {
      await loadClientState()
    }
  }

  async function loadClientState(): Promise<void> {
    /* todo: avoid concurrent requests. I think, the best way is to cancel old requests
            it will work in case of sequential changes in the client state

            We can't prevent request if state is 'loading' because it may be outdated at the moment
        */
    ClientState.value.state = 'loading'

    try {
      const accountStore = useAccountStore()
      const { debounce } = useCommon()

      ClientState.value.data = await clientStateAPI.getClientState()
      ClientState.value.state = 'success'

      if (accountStore.isLoggedIn && accountStore.BirthdayGifts.state !== 'loading') {
        debounce(async () => await accountStore.loadBirthdayGifts())
      }
    } catch (error) {
      ClientState.value.error = error
      ClientState.value.state = 'error'
    }
  }

  async function loadOrderProcessing(orderId: GUID): Promise<OrderProcessing | null> {
    const { CurrentRestaurantId } = useAppConfig()

    try {
      return await clientStateAPI.loadOrderProcessing(CurrentRestaurantId, orderId)
    } catch (error) {
      console.error('store request error', error)

      return null
    }
  }

  async function loadOrderInfo(orderId: GUID): Promise<OrderInfo | null> {
    const orderData: StoreState<OrderInfo> = OrdersInfo.value[orderId]

    if (!orderData) {
      OrdersInfo.value[orderId] = {
        state: 'error',
        error: null,
        data: null
      }
    }

    if (!orderData?.data && orderData?.state !== 'loading') {
      OrdersInfo.value[orderId].state = 'loading'

      try {
        OrdersInfo.value[orderId].data = await clientStateAPI.loadOrderInfo(orderId)
        OrdersInfo.value[orderId].state = 'success'
      } catch (error) {
        console.error('store request error', error)
        OrdersInfo.value[orderId].state = 'error'
        OrdersInfo.value[orderId].error = error

        return null
      }
    }

    return OrdersInfo.value[orderId].data
  }

  async function refreshOrderStatus(orderId: GUID): Promise<OrderInfoStatus> {
    try {
      return clientStateAPI.refreshOrderInfo(orderId)
    } catch (error) {
      console.error('store request error', error)

      return {
        Number: '',
        Status: OrderStatus.Undefined,
        StatusTitle: null,
        TipsLink: ''
      }
    }
  }

  async function repeatOrder(orderId: GUID): Promise<StateChangeAnswer | null> {
    try {
      return clientStateAPI.repeatOrder(orderId)
    } catch (error) {
      const popupStore = usePopupStore()

      if (popupStore.currentPopup !== null) await popupStore.closePopup()

      await popupStore.showException(error)

      return null
    }
  }

  async function updateOrderData(payload: StateOrderDataPayload): Promise<void> {
    if (ClientState.value.state !== 'success' || !ClientState.value.data) return

    const stateOrderData: StateOrderData | undefined = ClientState.value.data.StateOrderData
    const captchaResponse = payload.captchaResponse ?? stateOrderData?.CaptchaResponse ?? '',
      captchaResponseV3 = payload.captchaResponseV3 ?? stateOrderData?.CaptchaResponseV3 ?? '',
      change = payload.change ?? stateOrderData?.Change ?? '',
      comment = payload.comment ?? stateOrderData?.Comment ?? '',
      deliverRightNow = payload.deliverRightNow ?? stateOrderData?.DeliverRightNow ?? false,
      deliverRightNowWithASAP =
        (deliverRightNow && TimeRestrictions.value.data?.ASAP?.AvailableNow) ?? false,
      deliveryTime = payload.deliveryTime ?? stateOrderData?.DeliveryTime ?? 0,
      email = payload.email ?? stateOrderData?.Email ?? '',
      gratuity = payload.gratuity ?? stateOrderData?.Gratuity ?? 0,
      name = payload.name ?? stateOrderData?.Name ?? '',
      paymentType = payload.paymentType ?? stateOrderData?.PaymentType ?? 0,
      personCount = payload.personCount ?? stateOrderData?.PersonCount ?? 0,
      phone = payload.phone ?? stateOrderData?.Phone ?? '',
      receiptRecipient = payload.receiptRecipient ?? stateOrderData?.ReceiptRecipient ?? '',
      receiptSendType = payload.receiptSendType ?? stateOrderData?.ReceiptSendType ?? 0

    const restaurantStore = useRestaurantStore()
    const { isMobileBrowser } = useCommon()

    const version = isMobileBrowser() ? 'mobile' : 'desktop'

    await clientStateAPI.stateOrderDataChange(
      restaurantStore.Source ? restaurantStore.Source : `site-${version}`,
      name,
      phone,
      email,
      change,
      personCount,
      paymentType,
      gratuity,
      deliveryTime,
      deliverRightNowWithASAP,
      captchaResponse,
      captchaResponseV3,
      receiptSendType,
      receiptRecipient,
      comment
    )

    if (ClientState.value.data) {
      ClientState.value.data.StateOrderData = {
        Name: name,
        Phone: phone,
        Email: email,
        Change: change,
        PersonCount: personCount,
        PaymentType: paymentType,
        Gratuity: gratuity,
        DeliveryTime: deliveryTime,
        DeliverRightNow: deliverRightNowWithASAP,
        CaptchaResponse: captchaResponse,
        CaptchaResponseV3: captchaResponseV3,
        ReceiptSendType: receiptSendType,
        ReceiptRecipient: receiptRecipient,
        Comment: comment
      }
    }

    if (payload.refreshState) await loadClientState()
  }

  async function updateDeliveryAddress(address: addressChangeObject, overwrite: boolean): Promise<void> {
    if (!address) return
    if (!ClientState.value.data) return

    const addressCoordinates: number[] = []

    if (
      address.addressCoordinates &&
      address.addressCoordinates.Latitude &&
      address.addressCoordinates.Longitude
    ) {
      addressCoordinates.push(address.addressCoordinates.Latitude, address.addressCoordinates.Longitude)
    }

    const street = Object.hasOwn(address, 'street')
      ? (address.street ?? '')
      : (ClientState.value.data?.SelectedDeliveryAddress?.Street ?? '')

    const house = Object.hasOwn(address, 'house')
      ? (address.house ?? '')
      : (ClientState.value.data?.SelectedDeliveryAddress?.House ?? '')

    const apartment =
      Object.hasOwn(address, 'apartment') || overwrite
        ? (address.apartment ?? '')
        : (ClientState.value.data?.SelectedDeliveryAddress?.Apartment ?? '')

    const building =
      Object.hasOwn(address, 'building') || overwrite
        ? (address.building ?? '')
        : (ClientState.value.data?.SelectedDeliveryAddress?.Building ?? '')

    const corpus =
      Object.hasOwn(address, 'corpus') || overwrite
        ? (address.corpus ?? '')
        : (ClientState.value.data?.SelectedDeliveryAddress?.Corpus ?? '')

    const doorway =
      Object.hasOwn(address, 'doorway') || overwrite
        ? (address.doorway ?? '')
        : (ClientState.value.data?.SelectedDeliveryAddress?.Doorway ?? '')

    const floor =
      Object.hasOwn(address, 'floor') || overwrite
        ? (address.floor ?? '')
        : (ClientState.value.data?.SelectedDeliveryAddress?.Floor ?? '')

    const intercom =
      Object.hasOwn(address, 'intercom') || overwrite
        ? (address.intercom ?? '')
        : (ClientState.value.data?.SelectedDeliveryAddress?.Intercom ?? '')

    const zipCode =
      Object.hasOwn(address, 'zipCode') || overwrite
        ? (address.zipCode ?? '')
        : (ClientState.value.data?.SelectedDeliveryAddress?.ZipCode ?? '')

    const orderDeliveryType =
      Object.hasOwn(address, 'orderDeliveryType') || overwrite
        ? (address.orderDeliveryType ?? 0)
        : (ClientState.value.data?.SelectedDeliveryAddress?.OrderDeliveryType ?? 0)

    ClientState.value.data.SelectedDeliveryAddress = {
      AddressCoordinates:
        address.addressCoordinates ??
        ClientState.value.data?.SelectedDeliveryAddress?.AddressCoordinates ??
        {},
      AddressString:
        address.string ?? ClientState.value.data?.SelectedDeliveryAddress?.AddressString ?? '',
      GeoRegion: address.geoRegion ?? ClientState.value.data?.SelectedDeliveryAddress?.GeoRegion ?? '',
      GeoCity: address.geoCity ?? ClientState.value.data?.SelectedDeliveryAddress?.GeoCity ?? '',
      GeoDistrict:
        address.geoDistrict ?? ClientState.value.data?.SelectedDeliveryAddress?.GeoDistrict ?? '',
      CityID: address.cityId ?? ClientState.value.data?.SelectedDeliveryAddress?.CityID ?? Guid.Empty,
      DepartmentID:
        address.departmentId ??
        ClientState.value.data?.SelectedDeliveryAddress?.DepartmentID ??
        Guid.Empty,
      DistrictID:
        address.districtId ?? ClientState.value.data?.SelectedDeliveryAddress?.DistrictID ?? Guid.Empty,
      TerminalID:
        address.terminalId ?? ClientState.value.data?.SelectedDeliveryAddress?.TerminalID ?? Guid.Empty,

      //optional values
      Street: street,
      House: house,
      Apartment: apartment,
      Building: building,
      Corpus: corpus,
      Doorway: doorway,
      Intercom: intercom,
      Floor: floor,
      OrderDeliveryType: orderDeliveryType,
      ZipCode: zipCode
    }

    if (address && address.street && address.house) {
      ClientState.value.data.DeliveryInputError = false
    }

    await clientStateAPI.addressChange(
      ClientState.value.data.SelectedDeliveryAddress.TerminalID,
      ClientState.value.data?.OrderType === OrderType.CourierDelivery,
      ClientState.value.data?.OrderType === OrderType.InHall,
      ClientState.value.data.SelectedDeliveryAddress.CityID,
      ClientState.value.data.SelectedDeliveryAddress.DistrictID,
      ClientState.value.data.SelectedDeliveryAddress.DepartmentID,
      ClientState.value.data.SelectedDeliveryAddress.GeoRegion,
      ClientState.value.data.SelectedDeliveryAddress.GeoCity,
      ClientState.value.data.SelectedDeliveryAddress.GeoDistrict,
      street,
      house,
      ClientState.value.data.SelectedDeliveryAddress.AddressString,
      addressCoordinates,
      apartment,
      corpus,
      building,
      doorway,
      intercom,
      floor,
      orderDeliveryType,
      zipCode,
      ClientState.value.data.SelectedInHall?.TableNumber
    )

    await loadClientState()
    await loadTimeRestrictions()
  }
  async function updateDeliveryAddressWithAnotherAddress(
    address: SelectedDeliveryAddress
  ): Promise<void> {
    if (!ClientState.value.data) return
    ClientState.value.data.SelectedDeliveryAddress = address

    let coords: number[] | null = null
    const addressCoordinates = address.AddressCoordinates
    if (addressCoordinates && addressCoordinates.Latitude && addressCoordinates.Longitude) {
      coords = []

      coords.push(addressCoordinates.Latitude, addressCoordinates.Longitude)
    }

    if (address) {
      await clientStateAPI.addressChange(
        address.TerminalID ?? '',
        ClientState.value.data?.OrderType === OrderType.CourierDelivery,
        ClientState.value.data?.OrderType === OrderType.InHall,
        address.CityID ?? '',
        address.DistrictID ?? '',
        address.DepartmentID ?? '',
        address?.GeoRegion ?? '',
        address?.GeoCity ?? '',
        address?.GeoDistrict ?? '',
        address.Street ?? '',
        address.House ?? '',
        address.AddressString ?? '',
        coords,
        address.Apartment ?? '',
        address.Corpus ?? '',
        address.Building ?? '',
        address.Doorway ?? '',
        address.Intercom ?? '',
        address.Floor ?? '',
        address.OrderDeliveryType ?? 0,
        address.ZipCode ?? ''
      )

      await loadClientState()
      await loadTimeRestrictions()
    }
  }

  async function updateDeliveryAddressSingle(payload: addressChangeObject): Promise<void> {
    if (!ClientState.value.data?.SelectedDeliveryAddress) return
    const apartment = payload.apartment ?? ClientState.value.data?.SelectedDeliveryAddress.Apartment,
      building = payload.building ?? ClientState.value.data?.SelectedDeliveryAddress.Building,
      cityId = payload.cityId ?? ClientState.value.data?.SelectedDeliveryAddress.CityID,
      corpus = payload.corpus ?? ClientState.value.data?.SelectedDeliveryAddress.Corpus,
      departmentId =
        payload.departmentId ?? ClientState.value.data?.SelectedDeliveryAddress.DepartmentID,
      districtId = payload.districtId ?? ClientState.value.data?.SelectedDeliveryAddress.DistrictID,
      doorway = payload.doorway ?? ClientState.value.data?.SelectedDeliveryAddress.Doorway,
      floor = payload.floor ?? ClientState.value.data?.SelectedDeliveryAddress.Floor,
      geoCity = ClientState.value.data?.SelectedDeliveryAddress.GeoCity,
      geoDistrict = ClientState.value.data?.SelectedDeliveryAddress.GeoDistrict,
      geoRegion = ClientState.value.data?.SelectedDeliveryAddress.GeoRegion,
      intercom = payload.intercom ?? ClientState.value.data?.SelectedDeliveryAddress.Intercom,
      orderDeliveryType =
        payload.orderDeliveryType ?? ClientState.value.data?.SelectedDeliveryAddress?.OrderDeliveryType,
      tableNumber = payload.tableNumber ?? ClientState.value.data?.SelectedInHall?.TableNumber,
      terminalId = payload.terminalId ?? selectedTerminalId.value,
      zipCode = payload.zipCode ?? ClientState.value.data?.SelectedDeliveryAddress.ZipCode ?? ''
    let addressString = ClientState.value.data?.SelectedDeliveryAddress.AddressString ?? '',
      house = ClientState.value.data?.SelectedDeliveryAddress.House,
      street = ClientState.value.data?.SelectedDeliveryAddress.Street

    if (payload.house || payload.house === '') {
      house = payload.house

      const oldHouse = ClientState.value.data?.SelectedDeliveryAddress?.House ?? ''
      addressString = ClientState.value.data?.SelectedDeliveryAddress?.AddressString ?? ''
      if (addressString) {
        if (oldHouse === '') {
          addressString += `, ${house ?? ''}`
        } else {
          addressString = ClientState.value.data?.SelectedDeliveryAddress?.AddressString?.replace(
            `, ${oldHouse}`,
            `, ${house ?? ''}`
          )
        }
      }
    }

    if (payload.street || payload.street === '') {
      street = payload.street

      const oldStreet = ClientState.value.data?.SelectedDeliveryAddress?.Street ?? ''

      addressString = ClientState.value.data?.SelectedDeliveryAddress?.AddressString?.replace(
        `, ${oldStreet}`,
        `, ${street ?? ''}`
      )
    }

    if (street && house) ClientState.value.data.DeliveryInputError = false

    //TODO check this code and remove
    if (ClientState.value.data && ClientState.value.data.SelectedDeliveryAddress) {
      if (payload.apartment) ClientState.value.data.SelectedDeliveryAddress.Apartment = payload.apartment
      if (payload.corpus) ClientState.value.data.SelectedDeliveryAddress.Corpus = payload.corpus
      if (payload.building) ClientState.value.data.SelectedDeliveryAddress.Building = payload.building
      if (payload.doorway) ClientState.value.data.SelectedDeliveryAddress.Doorway = payload.doorway
      if (payload.intercom) ClientState.value.data.SelectedDeliveryAddress.Intercom = payload.intercom
      if (payload.floor) ClientState.value.data.SelectedDeliveryAddress.Floor = payload.floor
      if (payload.orderDeliveryType)
        ClientState.value.data.SelectedDeliveryAddress.OrderDeliveryType = payload.orderDeliveryType
    }

    let coords: number[] | null = null
    const coordsInState = ClientState.value.data?.SelectedDeliveryAddress?.AddressCoordinates
    if (coordsInState && coordsInState.Latitude && coordsInState.Longitude) {
      coords = []

      coords.push(coordsInState.Latitude, coordsInState.Longitude)
    }

    await clientStateAPI.addressChange(
      terminalId,
      !payload.tableNumber,
      !!payload.tableNumber,
      cityId,
      districtId,
      departmentId,
      geoRegion,
      geoCity,
      geoDistrict,
      street,
      house,
      addressString,
      coords,
      apartment ?? '',
      corpus ?? '',
      building ?? '',
      doorway ?? '',
      intercom ?? '',
      floor ?? '',
      orderDeliveryType ?? 0,
      zipCode ?? '',
      tableNumber ?? 0
    )

    await loadClientState()
    await loadTimeRestrictions()
  }

  async function loadTimeRestrictions(): Promise<void> {
    if (ClientState.value.state !== 'loading') {
      TimeRestrictions.value.state = 'loading'

      const orderType = ClientState.value.data?.OrderType ?? OrderType.Default

      if (orderType === null || orderType === OrderType.Default) {
        TimeRestrictions.value.data = null
        TimeRestrictions.value.state = 'success'

        return
      }

      if (!selectedTerminalId.value || Guid.IsNullOrEmpty(selectedTerminalId.value)) {
        TimeRestrictions.value.data = null
        TimeRestrictions.value.state = 'success'

        return
      }

      try {
        TimeRestrictions.value.data = await clientStateAPI.getTimeRestrictions(
          selectedTerminalId.value,
          orderType
        )
        TimeRestrictions.value.state = 'success'

        // if ASAP isn't available - disable it
        if (!!TimeRestrictions.value.data && !TimeRestrictions.value.data.ASAP?.AvailableNow) {
          DeliveryTime.value.ASAP = false
        }
      } catch (error) {
        TimeRestrictions.value.error = error
        TimeRestrictions.value.state = 'error'
      }
    }
  }

  async function reloadTimeRestrictions(): Promise<void> {
    // already in progress
    if (TimeRestrictions.value.state === 'loading') {
      return
    }

    // maintain minimal update period (don't let it slide below)
    const now = DateTime.utc()
    const minUpdatePeriod = TimeRestrictions.value.minUpdatePeriod
    const lastUpdated = TimeRestrictions.value.wasUpdatedAt
    if (lastUpdated && now.diff(lastUpdated).as('minutes') < minUpdatePeriod) {
      return
    }

    await loadTimeRestrictions()
  }

  async function stateUpdated(): Promise<void> {
    if (TimeRestrictions.value.state !== null) {
      await loadTimeRestrictions()
    }

    await loadClientState()

    const menuStore = useMenuStore()
    await menuStore.loadGiftsRanged()
  }

  async function applyPromoCode(promocode: string, showMessages = true): Promise<void> {
    const popupStore = usePopupStore()

    try {
      const stateChangeAnswer = await cartAPI.activePromo(promocode)

      await stateUpdated()
      if (stateChangeAnswer !== null && !stateChangeAnswer.PromoV2.IsPromoApplied && showMessages) {
        await popupStore.showWarning(stateChangeAnswer.PromoV2.ErrorMessages.join('<br/>'))
      }

      const accountStore = useAccountStore()
      if (accountStore.isLoggedIn) {
        await accountStore.loadPartialPay()
      }
    } catch (error) {
      let message = ''
      switch (error.code) {
        case 16:
          message = 'promocode.promoErrorCode16'
          break
        case 32:
          message = 'promocode.promoErrorCode32'
          break
        case 64:
          message = 'promocode.promoErrorCode64'
          break
        case 128:
          message = 'promocode.promoErrorCode128'
          break
        case 256:
          message = 'promocode.promoErrorCode256'
          break
        case 512:
          message = 'promocode.promoErrorCode512'
          break
        default:
          message = 'promocode.promoErrorDefault'
          break
      }

      await popupStore.showWarning(message)
    }
  }

  async function checkPromoCode(promocode: string): Promise<PromoInfo | null> {
    try {
      return await cartAPI.checkPromo(promocode)
    } catch (error) {
      console.warn(error)

      return null
    }
  }

  async function clearPromoCode(): Promise<void> {
    try {
      await cartAPI.clearPromo()

      await stateUpdated()

      const accountStore = useAccountStore()
      if (accountStore.isLoggedIn) {
        await accountStore.loadPartialPay()
      }
    } catch (error) {
      const popupStore = usePopupStore()
      await popupStore.showWarning(error.message)
    }
  }

  /**
   * Updates cart item count, or it's content (not yet implemented)
   * @param {Object} payload - object with fields itemId, count
   */
  async function updateCartItem(payload: UpdateCartItemPayload): Promise<StateChangeAnswer> {
    try {
      const updateReport = await cartAPI.updateItem(payload.itemId, payload.count, payload.confirm)

      await stateUpdated()

      return updateReport
    } catch (error) {
      const popupStore = usePopupStore()
      await popupStore.showException(error)

      console.error('Failed to update cart item', error)
      throw error
    }
  }

  async function addProductToCart(payload: AddToCartPayload): Promise<StateChangeAnswer> {
    try {
      const restaurantStore = useRestaurantStore()

      const updateReport: StateChangeAnswer = await cartAPI.addItem(
        payload.product,
        payload.firstAddType,
        payload.slots,
        payload.confirm
      )

      restaurantStore.Metrics?.sendMetricAddToCart(
        payload.product.groupId,
        payload.product.productID ?? payload.product.productId ?? Guid.Empty,
        payload.product.name,
        payload.product.priceModified,
        payload.product.count,
        payload.product.optionID ?? payload.product.optionId ?? Guid.Empty
      )

      await stateUpdated()

      return updateReport
    } catch (error) {
      const popupStore = usePopupStore()
      await popupStore.showException(error)

      console.error('Failed to add cart item', error)
      throw error
    }
  }

  async function deleteCartItem(payload: {
    itemId: GUID
    groupID: GUID
    name: string
    itemCount: number
    confirmed: boolean
  }): Promise<void> {
    try {
      const restaurantStore = useRestaurantStore()

      await cartAPI.deleteItem(payload.itemId, payload.itemCount, payload.confirmed)

      restaurantStore.Metrics?.sendMetricRemoveFromCart(
        payload.itemId,
        payload.groupID,
        payload.name,
        payload.itemCount
      )
      await stateUpdated()
    } catch (error) {
      const popupStore = usePopupStore()
      await popupStore.showException(error)

      console.error('Failed to delete cart item', error)
      throw error
    }
  }

  //восстановление продукта
  async function restoreCartItem(
    itemId: GUID,
    confirm?: boolean | undefined
  ): Promise<StateChangeAnswer> {
    try {
      const updateReport: StateChangeAnswer = await cartAPI.restoreCartItem(itemId, confirm)

      await stateUpdated()

      return updateReport
    } catch (error) {
      const popupStore = usePopupStore()
      await popupStore.showException(error)

      console.error('Failed to restore cart item', error)
      throw error
    }
  }

  async function setCallbackState(payload: boolean | null): Promise<void> {
    try {
      await cartAPI.setCallbackState(payload ?? false)

      await stateUpdated()
    } catch (error) {
      console.error('Failed to change callback state', error)
      throw error
    }
  }

  async function setStateSource(uuid: string): Promise<void> {
    try {
      await clientStateAPI.setStateSource(uuid)
    } catch (error) {
      console.error('Failed to change state source', error)
      throw error
    }
  }

  async function addServiceFee(payload: boolean | null): Promise<void> {
    try {
      await cartAPI.addServiceFee(payload ?? false)
      await loadClientState()
    } catch (error) {
      console.error('Failed to change serviceFee state', error)
    }
  }

  async function priorityService(payload: boolean | null): Promise<void> {
    try {
      await cartAPI.priorityService(payload ?? false)
      await loadClientState()
    } catch (error) {
      console.error('Failed to change priorityService state', error)
    }
  }

  async function selectOrderType(payload: { orderType: number }): Promise<void> {
    if (ClientState.value.data) {
      if (payload.orderType !== null) {
        ClientState.value.data.OrderType = payload.orderType

        await clientStateAPI.changeOrderType(payload.orderType)

        await loadTimeRestrictions()
        await loadClientState()
      }
    }
  }

  async function updateSelfServiceTerminal(payload: { terminalId: GUID }): Promise<void> {
    if (!ClientState.value.data) return

    ClientState.value.data.SelectedSelfserviceTerminalID = payload.terminalId

    await clientStateAPI.addressChange(
      payload.terminalId,
      ClientState.value.data?.OrderType === OrderType.CourierDelivery,
      ClientState.value.data?.OrderType === OrderType.InHall
    )

    await loadTimeRestrictions()
    await loadClientState()
  }

  async function wrongDeliveryInput(payload: { value: boolean }): Promise<void> {
    if (ClientState.value.data) ClientState.value.data.DeliveryInputError = payload.value
  }

  async function showPopupAboutInHall(forcePopup: boolean): Promise<void> {
    const popupAboutInHallSeenAtEntrance = useCookie('popupAboutInHallSeenAtEntrance')

    if (forcePopup || !popupAboutInHallSeenAtEntrance.value) {
      const popupStore = usePopupStore()
      const { translate } = useI18nSanitized()
      await popupStore.openPopup({
        popupName: 'messagePopup',
        popupClosable: true,
        popupProperties: new Map<string, unknown>([
          ['message', translate('inHallTableSelect.inHallWarning')],
          ['type', 'warning']
        ]),
        onClosePopup: () => {
          popupAboutInHallSeenAtEntrance.value = 'true'
        }
      })
    }
  }

  async function sendOrderV3(): Promise<CartAnswer> {
    const restaurantStore = useRestaurantStore()

    const answer = await cartAPI.sendOrderV3()

    await restaurantStore.Metrics?.sendMetricAfterOrder(answer.OrderID, answer.TotalAmountToPay)

    return answer
  }

  async function initOrdersForCancel(): Promise<void> {
    if (OrdersForCancel.value.state !== 'success' && OrdersForCancel.value.state !== 'loading') {
      await loadOrdersForCancel()
    }
  }

  async function loadOrdersForCancel(): Promise<void> {
    OrdersForCancel.value.state = 'loading'
    OrdersForCancel.value.data = null
    try {
      const appConfig = useAppConfig()
      const extraProducts = await clientStateAPI.getOrdersForCancel()
      OrdersForCancel.value.data = extraProducts.filter((order) => {
        const { fromMillisInZone } = useDateTime()
        const date = fromMillisInZone(order.OrderLifeTime * 1000, appConfig.RestaurantSettingsPreRun.GMT)

        return Math.floor(date.diffNow(['seconds']).seconds) > 0
      })
      OrdersForCancel.value.state = 'success'
    } catch (error) {
      OrdersForCancel.value.error = error
      OrdersForCancel.value.state = 'error'
    }
  }

  async function cancelOrder(orderId: GUID): Promise<string> {
    return await clientStateAPI.cancelOrder(orderId)
  }

  async function getUnavailableProducts(): Promise<CartItem[]> {
    await loadClientState()

    return ClientState.value.data?.Cart?.Content?.filter((cartItem) => !cartItem.Active) ?? []
  }

  async function needToShowProductsWithPriceChanges(): Promise<boolean> {
    try {
      productsWithPriceChanges.value = await cartAPI.getItemsPriceChanges()

      return true
    } catch (error) {
      const popupStore = usePopupStore()
      await popupStore.showException(error)

      console.error('Failed to get products with price changes', error)

      return false
    }
  }

  async function removeProductsFromCart(removeOnlyUnavailableProducts: boolean): Promise<boolean> {
    try {
      const response = await cartAPI.removeProductsFromCart(removeOnlyUnavailableProducts)
      if (response) await loadClientState()

      return response
    } catch (error) {
      console.error('store request error', error)
      return false
    }
  }

  async function getNotifications(): Promise<void> {
    try {
      const utcNow = DateTime.utc()
      const _response = await cartAPI.getNotifications(
        Math.round(utcNow.toMillis() / 1000),
        Math.round(utcNow.minus({ year: 1 }).toMillis() / 1000)
      )
    } catch (error) {
      console.error('store request error', error)
    }
  }

  return {
    ClientState,
    DeliveryTime,
    OrdersForCancel,
    OrdersInfo,
    TimeRestrictions,
    Breadcrumbs,
    BreadcrumbBackLink,
    BreadcrumbBackText,

    addProductToCart,
    applyPromoCode,
    averageTimeWithDelayMs,
    cancelOrder,
    checkPromoCode,
    clearPromoCode,
    courierDelivery,
    deleteCartItem,
    getNotifications,
    getUnavailableProducts,
    haveDelayInAverageTime,
    inHall,
    initClientState,
    initOrdersForCancel,
    loadClientState,
    loadOrderInfo,
    loadOrderProcessing,
    loadOrdersForCancel,
    needToShowProductsWithPriceChanges,
    onChangeSelfServiceTerminal,
    orderTypeNotSelected,
    orderTypeText,
    productsWithPriceChanges,
    refreshOrderStatus,
    reloadTimeRestrictions,
    removeProductsFromCart,
    repeatOrder,
    restoreCartItem,
    selectOrderType,
    selectedTerminalId,
    selfService,
    sendOrderV3,
    setCallbackState,
    setStateSource,
    addServiceFee,
    priorityService,
    showPopupAboutInHall,
    stateUpdated,
    updateCartItem,
    updateDeliveryAddress,
    updateDeliveryAddressSingle,
    updateDeliveryAddressWithAnotherAddress,
    updateOrderData,
    updateSelfServiceTerminal,
    wrongDeliveryInput
  }
})
