<template>
  <div class="v-arora-chip-wrapper v-scrollbar v-d-flex v-flex-row-no-wrap v-pl-0">
    <template v-if="withLinks">
      <arora-nuxt-link
        v-for="(chip, index) in chips as ChipsSettings<T>[]"
        :key="`chips-${uid}-${index.toString()}`"
        :class-name="
          selected === chip.ID
            ? `v-arora-chip v-arora-chip--${theme}__selected`
            : `v-arora-chip v-arora-chip--${theme}__default`
        "
        :data-test-id="dataTestId"
        :external-link="!chip.Link?.startsWith('/')"
        :href="chip.Link ?? ''"
        :label="chip.Name"
        :open-in-new-tab="!chip.Link?.startsWith('/')"
        @click="() => select(chip.ID)"
      />
    </template>
    <template v-else>
      <div
        v-for="(chip, index) in chips as ChipsSettings<T>[]"
        :key="`chips-${uid}-${index.toString()}`"
        class="v-arora-chip"
        :class="[
          selected === chip.ID ? `v-arora-chip--${theme}__selected` : `v-arora-chip--${theme}__default`
        ]"
        :data-test-id="dataTestId"
        @click="() => select(chip.ID)"
        v-html="chip.Name"
      />
    </template>
  </div>
</template>

<script setup lang="ts" generic="T extends GUID | number">
import type { ChipsSettings } from '~types/common'

import type { AutoTest, GUID } from '@arora/common'

const {
  chips,
  selected,
  theme = 'primary'
} = defineProps<
  AutoTest & {
    selected: T | null | undefined
    chips: ChipsSettings<T>[]
    withLinks?: boolean
    theme?: 'primary' | 'secondary'
  }
>()

const emit = defineEmits(['update:selected'])

function select(id: T): void {
  emit('update:selected', id)
}

watch(
  () => chips,
  (newValue, oldValue) => {
    if (newValue.length !== oldValue.length && newValue.length > 0) {
      emit('update:selected', newValue[0].ID)
    }
  }
)

onMounted(() => {
  if (!selected && chips.length > 0) emit('update:selected', chips[0].ID)
})

const uid = useId()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-arora-chip-wrapper {
  gap: 12px;
  overflow-y: auto;

  padding-bottom: 0.9rem;

  .v-arora-chip {
    @include mixins.trans;

    border: 1px solid variables.$BorderColor;
    border-radius: variables.$BorderRadius;
    padding: 8px 12px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;

    @include mixins.lg {
      height: 38px;
    }

    @include mixins.sm {
      height: 36px;
    }

    &:hover {
      cursor: pointer;
    }

    &--primary {
      &__default {
        background-color: variables.$BodyBackgroundDarken;
        color: variables.$BodyTextColor;

        &:hover {
          background-color: variables.$BodyBackgroundDarker;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }

      &__selected {
        background-color: variables.$PrimaryBackgroundColor;
        color: variables.$PrimaryColor;
        border: 1px solid variables.$PrimaryBackgroundColor;

        &:hover {
          background-color: variables.$PrimaryBackgroundColorDarken;
          color: variables.$PrimaryColor;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }
    }

    &--secondary {
      &__default {
        background-color: variables.$BodyBackgroundDarken;
        color: variables.$BodyTextColor;

        &:hover {
          background-color: variables.$BodyBackgroundDarker;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }

      &__selected {
        background-color: variables.$SecondaryBackgroundColor;
        color: variables.$SecondaryColor;
        border: 1px solid variables.$SecondaryBackgroundColor;

        &:hover {
          background-color: variables.$SecondaryBackgroundColorDarken;
          color: variables.$SecondaryColor;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }
    }
  }
}
</style>
